import { useQuery } from '@tanstack/react-query';
import Keys from './query.keys';
import api from '@/api';

const getChannelConfigByRef = (refId: string) =>
useQuery<any, Error>(
  Keys.channelConfigByRef(refId),
  () => api.channel.getConfigByRef(refId).then(({ data }) => data),
  {
    enabled: !!refId,
  }
);

const getLiveStageList = (eventId: string) =>
useQuery<string[], Error>(
  Keys.liveStageList(eventId),
  () => api.channel.getLiveStageList(eventId).then(({ data }) => data)
);

export { Keys as ChannelQueryKeys };

export default {
    getChannelConfigByRef,
    getLiveStageList,
};

